(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['jquery'], function($) {
      return factory(root, $);
    });
  } else if (typeof exports === 'object') {
    // Node/CommonJS
    module.exports = factory(root, require('jquery'));
  } else {
    // Browser globals
    factory(root, jQuery);
  }
})(typeof window !== 'undefined' ? window : this, function(
  window,
  $,
  undefined
) {
  var defaults = {
    label: 'OK',
    autoClose: true,
  };

  let _alertOverlay, _alertWindow, _actionButton;

  let options = {};

  $.fn.extend({
    halfWidth: function() {
      var width = 0;
      this.each(function() {
        width += $(this).outerWidth() / 2;
      });
      return width;
    },
    halfHeight: function() {
      var height = 0;
      this.each(function() {
        height += $(this).outerHeight() / 2;
      });
      return height;
    },
  });
  function centerWindow() {
    _alertWindow.css({
      marginLeft: -_alertWindow.halfWidth(),
      marginTop: -_alertWindow.halfHeight(),
    });
  }

  function createWindow(msg) {
    var elements = $(
      "<div class='wow-alert-overlay'></div><div class='wow-alert-content'><p>" +
        msg +
        "</p><a href='#'>" +
        options.label +
        '</a></div>'
    );
    _alertOverlay = $(elements[0]);
    _alertWindow = $(elements[1]);

    _actionButton = _alertWindow.find('a');

    _alertOverlay.appendTo('body');
    _alertWindow.appendTo('body');

    return [_alertOverlay, _alertWindow];
  }

  function configureActions() {
    _actionButton.bind('click', function(e) {
      e.preventDefault();
      if (options.autoClose) close();
      if (options.success) options.success();
    });
  }

  function close() {
    _alertOverlay.remove();
    _alertWindow.remove();
  }
  window.alert = function(msg, opts) {
    options = $.extend(defaults, opts);

    createWindow(msg);
    centerWindow();
    configureActions();
  };
});
