import { throttle } from "throttle-debounce";
/* import hoverEffect from 'hover-effect'; */
import { Color } from "./color";

export class SliderNavigation {
  constructor(params) {
    this.params = $.extend(
      {
        containerSelector: ".msnt-slides",
        fadeThrottle: 350,
      },
      params
    );

    this.skipCache = 100;

    this.firstRun = true;

    this.autoscroll = false;
    this._lastScroll = 0;
    this._scrollDir = 1;
    this.playTimeout = null;

    this.scrollActive = true;

    this.scrollTimeout = null;

    this.initialSlide = true;

    this.slideTimeoutId = null;
    this.slideBgTimeout = 10000;
    this.blurBgId = null;
    this.blurBgTimeout = 150;

    this.animateValueTime = 2000;

    this.$window = $(window);
    this.$container = $(this.params.containerSelector);

    if (!this.$container.length) return;

    this.$container.removeClass("transition");

    this.$slides = this.$container.children();

    this.slideHashes = [
      ...this.$slides.map((index, el) => $(el).data("slide")),
    ];

    this.$nextSlide = $(".nextSlide");

    this.init();
    this.$container.trigger("msnt-slider-load");

    this.imagePromise = null;
  }

  init() {
    this.$slides.each((i, slide) => {
      //this._prepareSlide($(slide));
      this._getIcons($(slide));
    });

    this._detectAutoScroll();

    this.reload();

    this._initEvents();

    if (this.autoscroll) {
      this.$nextSlide.remove();
    } else {
      setTimeout(() => {
        this.$nextSlide.removeClass("hidden");

        setTimeout(() => {
          this.$nextSlide.addClass("animate");
        }, 300);
      }, 1000);
    }

    //this._setTransitionEffects();
  }

  reload() {
    const hash = window.location.hash.replace("#", "");

    const index = hash ? this.slideHashes.indexOf(hash) : 0;

    this.currentIndex = index;
    this.slideHeight = 0;
    this.$currentSlide = this.$slides.eq(index);

    this._initVars();

    this.$container.scrollTop(index * this.slideHeight);

    /* this.$slides.find('.msnt-slide_current').removeClass('msnt-slide_current');
    this.$currentSlide.addClass('msnt-slide_current'); */

    this.changeSlide(this.$slides.eq(index));
  }

  changeSlide($slide) {
    this.$slides
      .filter(".msnt-slide_current")
      .removeClass("msnt-slide_current");

    this._stopIconsAnimation(this.$currentSlide);

    this.$currentSlide = $slide;

    setTimeout(
      () => {
        this._playIconsAnimation($slide);
        this.firstRun = false;
      },
      this.firstRun ? 500 : 10
    );

    setTimeout(() => {
      this.$currentSlide.addClass("msnt-slide_current");
      this._prepareSlide(this._getPrevSlide());
      this._prepareSlide(this._getNextSlide());
    }, 10);

    this._prepareSlide(this.$currentSlide);

    window.location.hash = this.$currentSlide.data("slide");
    this.$container.trigger("msnt-hash-changed");

    this._setBorderColor();

    clearTimeout(this.slideTimeoutId);
    this.$window.trigger("mousemove");

    this._animateTitle(this.$currentSlide.find(".msnt-slide_title--animated"));
  }

  changeSlideBg() {
    clearTimeout(this.slideTimeoutId);

    if (!this.$currentSlide.data("init")) {
      return;
    }

    this.slideTimeoutId = setTimeout(() => {
      let count = 0;
      if (this.$currentSlide.data("count")) {
        count = this.$currentSlide.data("count");
      } else {
        this.$currentSlide.data("count", count);
      }

      if (this.autoscroll && count >= 1) {
        this.fadeSlide(this._getNextSlide());
        this.$currentSlide.data("count", 0);
      } else {
        if (this.autoscroll) {
          this.$currentSlide.data("count", count + 1);
        }

        this.imagePromise = this._loadImage(this.$currentSlide);

        this.$currentSlide
          .addClass("msnt-slide__blured")
          .removeClass("msnt-slide_current");

        this.imagePromise.then((image) => {
          this.blurBgId = setTimeout(() => {
            this.$currentSlide
              .find(".msnt-slide_bg-image")
              .css("background-image", "url(" + image.src + ")")
              .addClass("loaded");

            this.$currentSlide
              .removeClass("msnt-slide__blured")
              .addClass("msnt-slide_current");

            setTimeout(() => {
              this.changeSlideBg();
            }, 10);
          }, this.blurBgTimeout);
        });
      }
    }, this.slideBgTimeout);
  }

  scrollToNext() {
    var $next = this.$currentSlide.next();
    if (!$next.length) {
      var $firstSlide = this.getFirstSlide();
      this.fadeSlide($firstSlide);
    } else {
      this._scrollTo($next);
    }
  }

  fadeSlide($slide, callback) {
    this._stopIconsAnimation(this.$currentSlide);
    this.$container.stop().fadeTo(750, 0, () => {
      this._scrollTo($slide, 0);
      this._playIconsAnimation($slide);
      this.$currentSlide.removeClass("msnt-slide_current");
      this.$currentSlide = $slide;

      if (typeof callback == "function") {
        const autoscroll = this.autoscroll;
        this.autoscroll = false;
        callback.call(this);

        this.autoscroll = autoscroll;
      }

      if (this.$currentSlide[0] === $slide[0]) {
        this.$currentSlide.addClass("msnt-slide_delay");
        setTimeout(() => {
          this.$currentSlide
            .removeClass("msnt-slide_delay")
            .addClass("msnt-slide_current");
        }, 500);
        this.$container.delay(500).fadeTo(750, 1);
      } else {
        this.$container.fadeTo(750, 1);
      }
    });
  }

  getFirstSlide() {
    return this.$slides.first();
  }

  getLastSlide() {
    return this.$slides.last();
  }

  next() {
    let newIndex = this.currentIndex + 1;

    if (newIndex >= this.$slides.length) {
      newIndex = 0;
    }

    this.currentIndex = newIndex;
    this.$container.scrollTop(newIndex * this.slideHeight);
    this.changeSlide(this.$slides.eq(newIndex));
  }

  _getIcons($slide) {
    $slide = $slide || this.$currentSlide;

    var icons = $slide.data("icons");

    if (!icons) {
      icons = [];
      const $animations = $slide.find(".msnt-slide-item_image svg");
      $animations.each(function(i, elem) {
        icons.push(new Vivus(elem.id, { start: "manual" }));
      });

      $slide.data("icons", icons);
    }

    return icons;
  }

  _playIconsAnimation($slide) {
    const speed = 3;

    $slide = $slide || this.$currentSlide;

    if ($slide.data("play") == 1) {
      return;
    }

    $slide.data("play", 1);

    clearTimeout(this.playTimeout);

    this.playTimeout = setTimeout(() => {
      var icons = this._getIcons($slide);
      for (var i = 0; i < icons.length; i++) {
        icons[i].play(speed);
        $(icons[i].el).css("opacity", 1);
      }
    }, 100);

    $slide.find(".msnt-counter_target").each((i, e) => {
      if (!e.dataset.animate) {
        e.dataset.animate = 1;
        this._animateValue(
          e,
          e.dataset.decimal
            ? parseFloat(e.dataset.start)
            : parseInt(e.dataset.start),
          e.dataset.decimal
            ? parseFloat(e.dataset.value)
            : parseInt(e.dataset.value),
          this.animateValueTime,
          !!e.dataset.decimal
        );

        $(e).css("opacity", 1);
      }
    });
  }

  _stopIconsAnimation($slide, reset) {
    $slide = $slide || this.$currentSlide;
    reset = !!reset || false;

    if ($slide.data("play") != 1) {
      $slide.data("play", 0);
      return;
    }

    $slide.data("play", 0);

    clearTimeout(this.playTimeout);

    var icons = this._getIcons($slide);

    for (var i = 0; i < icons.length; i++) {
      var icon = icons[i];
      icon.stop();
      if (reset) {
        icon.reset();
      }
    }
  }

  _scrollTo($slide, time) {
    time = time || 100;

    const index = this.$slides.index($slide);

    this.scrollActive = false;

    this.$container
      // .addClass('transition')
      .animate(
        {
          scrollTop: index * this.slideHeight,
        },
        time,
        () => {
          // this.$container.removeClass('transition')
          this.scrollActive = true;
          this._checkCurrentSlide();
        }
      );
  }

  _prepareSlide($slide) {
    if (!$slide.length) {
      return;
    }

    if (!$slide.data("init")) {
      $slide.data("init", 1);

      this._loadImage($slide).then((image) => {
        $slide
          .find(".msnt-slide_bg-image")
          .css("background-image", "url(" + image.src + ")")
          .addClass("loaded");
      });

      // animate initial slide
      if (this.initialSlide) {
        this.initialSlide = false;

        this.$container.delay(0).animate({ opacity: 1 }, 0);

        setTimeout(() => {
          this._stopIconsAnimation($slide);
          this._playIconsAnimation($slide);
        }, 600);
      }
    }
  }

  _animateTitle($title) {
    if (!$title.length || $title.data("animated")) {
      return;
    }

    $title.each((i, el) => {
      const $el = $(el);
      const isRtl = $el.data("dir") === "rtl";

      $el.data("animated", 1);

      const $container = $("<span>").css({
        position: "absolute",
        width: "100%",
        height: "120%",
        left: isRtl ? "auto" : 0,
        right: isRtl ? 0 : "auto",
        top: "-10%",
      });

      const $box = $("<span>").css({
        position: "absolute",
        width: "calc(100% + 20px)",
        height: "100%",
        background: "#fff",
        left: isRtl ? "auto" : "-10px",
        right: isRtl ? "-10px" : "auto",
        top: 0,
        transform: "skewX(-20deg) scale(0, 1)",
        "will-change": "transform",
      });

      $el.append($container.append($box));

      setTimeout(() => {
        $box.animate(
          { transform: "translate3d(0px, 0px, 0px)" },
          {
            duration: 800,
            progress: (element, progress, left) => {
              if (progress <= 0.5) {
                $box.css({
                  "-webkit-transform":
                    "translate3d(0px, 0px, 0px) skew(-20deg, 0deg) scale(" +
                    progress * 2 +
                    ", 1)",

                  "transform-origin": isRtl ? "right center" : "left center",
                });
              } else {
                $box.css({
                  "-webkit-transform":
                    "translate3d(0px, 0px, 0px) skew(-20deg, 0deg) scale(" +
                    (1 - (progress - 0.5) * 2) +
                    ", 1)",

                  "transform-origin": isRtl ? "left center" : "right center",
                });

                $el.css("color", "#fff");
              }
            },
            complete: () => {
              $container.remove();
            },
          }
        );
      }, 700);
    });
  }

  _loadImage($slide) {
    var path =
      "/design/src/img/v2/" +
      (window.MSNTConfig.imagesPath ? window.MSNTConfig.imagesPath : "home/") +
      $slide.data("slide") +
      "/" +
      this._getSlideDir($slide) +
      this._getSlideImage($slide);

    return new Promise((resolve, reject) => {
      var image = new Image();

      image.onload = () => {
        resolve(image);
      };

      image.src = path;
    });
  }

  _getSlideDir($slide) {
    if (this.isSmall) {
      return "small/";
    } else if (
      this.isMedium &&
      $slide.find(".msnt-slide_bg").data("images-t")
    ) {
      return "medium/";
    }

    return "";
  }

  _getSlideImage($slide) {
    const $container = $slide.find(".msnt-slide_bg");
    let images = false;

    if (this.isSmall) {
      const sImages = $container.data("images-m");
      if (sImages) {
        images = sImages.split(",");
      }
    } else if (this.isMedium) {
      const sImages = $container.data("images-t");
      if (sImages) {
        images = sImages.split(",");
      }
    }

    if (!images) {
      images = $container.data("images").split(",");
    }

    let index = 0;

    if ($slide.data("index") !== undefined) {
      index = 1 + parseInt($slide.data("index"));
      if (index >= images.length) {
        index = 0;
      }
      $slide.data("index", index);
    } else {
      index = Math.floor(Math.random() * images.length);
      $slide.data("index", index);
    }

    let backgrounds = $container.data("backgrounds");

    if (backgrounds) {
      backgrounds = backgrounds.split(",");
    }

    $container.data(
      "bg-color",
      backgrounds ? backgrounds[index] || false : false
    );

    return images[index];
  }

  _initVars() {
    this.slideHeight = this.$slides.eq(0).height();

    /*this.$slides.css({
      height: this.slideHeight * 2,
      'margin-top': -this.slideHeight,
    });*/

    //this.$slides.find('.msnt-slides_wrapper').css({ height: this.slideHeight });

    this.isSmall = this.$window.width() < 1024;
    this.isMedium = !this.isSmall && this.$window.width() <= 1366;

    this.transparentBorder = this.$window.width() < 768;
  }

  _initEvents() {
    this.$container.on("scroll", (e) => {
      e.stopPropagation();
      $(document.scrollingElement).css("overflow", "hidden");
      if (!this.scrollActive) {
        return;
      }
      this._scrollDir = this._lastScroll < this.$container.scrollTop() ? 1 : -1;
      this._lastScroll = this.$container.scrollTop();
      this._checkCurrentSlide();
    });

    this.$slides.on("click", function(e) {
      return;
    });

    this.$window
      .on(
        "mousemove",
        throttle(this.slideBgTimeout / 10, this.changeSlideBg.bind(this))
      )
      .trigger("mousemove");

    $("body")
      .on("msnt-change-slide", () => {
        var hash = location.hash.replace("#", "");
        var $slide = this.$container.find('[data-slide="' + hash + '"]');
        if ($slide.length) {
          this._scrollTo($slide);
        }
      })
      .on("msnt-fade-slide", () => {
        var hash = location.hash.replace("#", "");
        var $slide = this.$container.find('[data-slide="' + hash + '"]');
        if ($slide.length) {
          this.fadeSlide($slide);
        }
      });

    $(window).on("resize", this.reload.bind(this));

    this.$nextSlide.on("click", () => {
      this.fadeSlide(this._getNextSlide());
    });
  }

  _checkCurrentSlide() {
    if (this.$container.scrollTop() === 0) {
      $(document.body).addClass("atTop");
    } else {
      $(document.body).removeClass("atTop");
    }

    if (!this.scrollActive) {
      return;
    }

    var scroll = this.$container.scrollTop() / this.slideHeight;
    var top = Math.round(scroll);

    if (top < 0) {
      top = 0;
    } else if (top > this.$slides.length) {
      top = this.$slides.length;
    }

    if (top !== this.currentIndex) {
      this.currentIndex = top;
      this.changeSlide(this.$slides.eq(top));
    }

    var half = Math.round(scroll);

    if (half > this.currentIndex && this._scrollDir > 0) {
      this._stopIconsAnimation(this.$slides.eq(this.currentIndex));
      this._playIconsAnimation(this.$slides.eq(this.currentIndex + 1));
    } else if (half <= this.currentIndex && this._scrollDir < 0) {
      this._stopIconsAnimation(this.$slides.eq(half - 1));
      this._playIconsAnimation(this.$slides.eq(half));
    }
  }

  _detectAutoScroll() {
    const search = window.location.search.substr(1);

    if (!search) return;

    let hasAutoScroll = false;
    const searhcSplit = search.split("&");

    searhcSplit.forEach((item) => {
      const itemSplit = item.split("=");

      if (itemSplit[0] === "autoscroll" && itemSplit[1] === "1") {
        hasAutoScroll = true;
      }
    });

    this.autoscroll = hasAutoScroll;
  }

  _setTransitionEffects() {
    return;
    const index = Math.floor(this.$container.scrollTop() / this.slideHeight);
    const percent = this.$container.scrollTop() / this.slideHeight - index;
    const $slide = this.$slides.eq(index);
    const currentColor = new Color(
      $slide.find(".msnt-slide_bg").data("bg-color"),
      this.transparentBorder
    );
    const targetColor = new Color(
      $slide
        .next()
        .find(".msnt-slide_bg")
        .data("bg-color"),
      this.transparentBorder
    );

    let mixinColor = currentColor.mix(targetColor, percent);
    $(".frame-border").css("background-color", mixinColor.hex);
  }

  _setBorderColor() {
    const currentColor = new Color(
      this.$currentSlide.find(".msnt-slide_bg").data("bg-color"),
      this.transparentBorder
    );

    $(".frame-border").css("background-color", currentColor.hex);
  }

  _getNextSlide() {
    var $next = this.$currentSlide.next();
    if (!$next.length) {
      return this.getFirstSlide();
    }
    return $next;
  }

  _getPrevSlide() {
    var $last = this.$currentSlide.prev();
    if (!$last.length) {
      return this.getLastSlide();
    }
    return $last;
  }

  _animateValue(obj, start, end, duration, decimal) {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);

      if (decimal) {
        obj.innerHTML =
          Math.floor((progress * (end - start) + start) * 10) / 10;
      } else {
        obj.innerHTML = Math.floor(progress * (end - start) + start);
      }

      if (progress < 1) {
        window.requestAnimationFrame(step);
      } else {
        obj.innerHTML = end;
      }
    };
    window.requestAnimationFrame(step);
  }
}
