import "../wow-alert";
import "../wheel-indicator";

import { PageHeader } from "../v2/Header";
import { PageMenu } from "../v2/Menu";
import { SliderNavigation } from "../v2/SliderNavigation";
import { Sidenav } from "../v2/Sidenav";
import { Vivus } from "../v2/vivus";
import { ImageChanger } from "../v2/ImageChanger";

window.Vivus = Vivus;

$(function() {
  const $preload = $(".msnt-preload");

  const Header = new PageHeader();
  const Menu = new PageMenu();
  const Nav = new Sidenav();
  const Banner = new ImageChanger();

  var sliderDelay = 0;

  $(".msnt-next-page").on("click", () => {
    Slider.fadeSlide(Slider._getNextSlide());
  });

  /**
   * Class constructor
   * option structure
   *   type: 'delayed'|'sync'|'oneByOne'|'script' (to know if the items must be drawn synchronously or not, default: delayed)
   *   duration: <int> (in frames)
   *   start: 'inViewport'|'manual'|'autostart' (start automatically the animation, default: inViewport)
   *   delay: <int> (delay between the drawing of first and last path)
   *   dashGap <integer> whitespace extra margin between dashes
   *   pathTimingFunction <function> timing animation function for each path element of the SVG
   *   animTimingFunction <function> timing animation function for the complete SVG
   *   forceRender <boolean> force the browser to re-render all updated path items
   *   selfDestroy <boolean> removes all extra styling on the SVG, and leaves it as original
   * */

  if ($preload.length) {
    $preload.addClass("loading");

    /* const PreloadSvg = $preload.find("svg");
    const iconAnim = new Vivus(PreloadSvg[0], {
      start: "manual",
      type: "scenario",
    });

    iconAnim.play(1);
    */

    sliderDelay = 1500;

    setTimeout(() => {
      $preload.toggleClass("loading loaded");
    }, sliderDelay);
  }

  setTimeout(() => {
    const Slider = new SliderNavigation();
  }, sliderDelay);
});
