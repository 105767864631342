export class ImageChanger {

    constructor(params) {
        this.params = $.extend(
          {
            containerSelector: '.msnt-header-banner',
          },
          params
        );

        this.$window = $(window);

        this.isSmall = this.$window.width() < 768;

        this.imageTimeoutId = null;
        this.imageBgTimeout = 10000;

        this.blurBgTimeout = 150;

        this.$window.on('resize', () => { this.resize() })

        this.init();
      }

    init() {

        this.$container = $(this.params.containerSelector);

        if (!this.$container.length) {
            return;
        }

        this.changeImage();
    }

    resize() {
        this.isSmall = this.$window.width() < 768;
    }

    changeImage() {
        this._loadImage(this.$container.data('folder'), this.$container.data('images'));

        return;

        // code for infinity change images
        this.imageTimeoutId = setTimeout(() => {
            this.changeImage();
        }, this.imageBgTimeout);
    }

    _loadImage(folder, image) {
        var path =
            '/design/src/img/' + folder + '/' +
            (this.isSmall ? 'small/' : '') +
            this._getImage();
    
        return new Promise((resolve, reject) => {
          var image = new Image();
    
          image.onload = () => {
            resolve(image);
          };
      
          image.src = path;
        }).then((image) => {
            this.$container.addClass('blured');
            setTimeout(() => {
                this.$container.css('background-image', 'url(' + image.src + ')')
                .addClass('loaded').removeClass('blured');
            }, this.blurBgTimeout);
        });
    }

    _getImage() {
        const images = this.$container.data('images').split(',');

        let index = 0;

        if (this.$container.data('index') !== undefined) {
            index = 1 + parseInt(this.$container.data('index'));
            if (index >= images.length) {
              index = 0;
            }
            this.$container.data('index', index);
        } else {
            index = Math.floor(Math.random() * images.length);
            this.$container.data('index', index);
        }

        return images[index];
    }
}