export class Sidenav {
  constructor() {
    this.$parent = $('.sidenav');

    if (!this.$parent.length) {
      return;
    }

    this.$container = $('.sidenav .sidenav__items');

    var skipCache = 1000;
    this._initVars();
    this._initEvents();
  }

  _initVars() {
    this.containerHeight = this.$container.outerHeight();
    this.itemHeight = this.$container
      .find('.sidenav__item')
      .eq(0)
      .outerHeight();
    this.scrollPos = this.$container.scrollTop();
  }

  _initEvents() {
    this.$parent.on('mouseenter', (e) => {
      if (this.$parent.hasClass('open')) return false;
      this.triggerOpen = this._isTouchDevice();
      this.$parent.addClass('open');
      return false;
    });

    this.$parent.on('mouseleave', (e) => {
      if (!this.$parent.hasClass('open')) return false;
      e.stopPropagation();
      e.preventDefault();
      this.$parent.removeClass('open');
      this.triggerOpen = false;
      return false;
    });

    this.$parent.on('click', '.sidenav__item', e => {
      e.stopPropagation();
      e.preventDefault();
      if (!this.$parent.hasClass('open') || this.triggerOpen) {
        this.triggerOpen = false;
        return false;
      }
      var $elem = $(e.currentTarget);
      var hash = $elem.data('slide');
      location.hash = hash;
      this.$parent.trigger('msnt-fade-slide');
      e.stopImmediatePropagation();
    });

    $('body')
      .on('msnt-hash-changed', () => {
        this._changeCurrentItem();
      })
      .on('msnt-slider-load', () => {
        this._changeCurrentItem();
      });

    $(window).on('resize', () => {
      this._initVars();
    });

    this.$container.on('scroll', e => {
      const scrollTop = this.$container.scrollTop();

      if (scrollTop <= 0) {
        this.$parent.find('.sidenav__controls-top').addClass('disabled');
      } else {
        this.$parent.find('.sidenav__controls-top').removeClass('disabled');
      }

      const scrollHeight = this.$container.prop('scrollHeight');

      if (
        scrollHeight <=
        this.containerHeight + scrollTop
      ) {
        this.$parent.find('.sidenav__controls-bottom').addClass('disabled');
      } else {
        this.$parent.find('.sidenav__controls-bottom').removeClass('disabled');
      }

      e.preventDefault();
      return false;
    });

    this.$container.trigger('scroll');

    this.$parent
      .find('.sidenav__controls-top')
      .on('mousedown', e => {
        const $target = $(e.currentTarget);

        if ($target.hasClass('disabled')) {
          return;
        }

        if (this.intervalID) {
          clearInterval(this.intervalID);
        }

        let top = this.$container.scrollTop() - this.itemHeight;

        if (top < 0) {
          top = 0;
        }

        this.$container.scrollTop(top);

        this.intervalID = setInterval(() => {
          this.$container.scrollTop(top);
        }, 100);
      })
      .on('mouseup', () => {
        clearInterval(this.intervalID);
      });

    this.$parent
      .find('.sidenav__controls-bottom')
      .on('mousedown', e => {
        const $target = $(e.currentTarget);

        if ($target.hasClass('disabled')) {
          return;
        }

        if (this.intervalID) {
          clearInterval(this.intervalID);
        }

        let top = this.$container.scrollTop() + this.itemHeight;

        if (top > this.$container.prop('scrollHeight')) {
          top = this.$container.prop('scrollHeight');
        }

        this.$container.scrollTop(top);

        this.intervalID = setInterval(() => {
          this.$container.scrollTop(top);
        }, 100);
      })
      .on('mouseup', () => {
        clearInterval(this.intervalID);
      });
  }

  _changeCurrentItem() {
    var hash = location.hash.replace('#', '');
    this.$container
      .find('.sidenav__item--active')
      .removeClass('sidenav__item--active');

    const $item = this.$container
      .find('.sidenav__item[data-slide="' + hash + '"]')
      .addClass('sidenav__item--active');

    if (!$item.length) {
      return;
    }

    const pos =
      $item.offset().top -
      this.$container.offset().top +
      this.$container.scrollTop();

    if (
      pos + this.itemHeight >
      this.containerHeight + this.$container.scrollTop()
    ) {
      this.$container.scrollTop(pos - this.containerHeight + this.itemHeight);
    } else if (pos < this.$container.scrollTop()) {
      this.$container.scrollTop(pos);
    }
  }

  _isTouchDevice() {
    try { 
      document.createEvent("TouchEvent");  
      return true;  
    } catch (e) {  
      return false;  
    }
  }
}
