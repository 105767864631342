export class PageMenu {
  constructor() {
    this.$container = $(".msnt-menu");
    this.$overlay = $(".msnt-menu_overlay");
    this.$button = $("#open-menu-btn");
    this.$body = $("body");

    this.menuOpened = this.$container.hasClass("msnt-menu--opened");

    this.$animMenus = $([])
      .add($(".msnt-menu_items").children())
      .add($(".msnt-menu_other-social"))
      .add($(".msnt-menu_other-copyright"));

    this.openMenuClass = "menuVisible";

    this._initEvents();
  }

  _initEvents() {
    this.$animMenus.addClass("msnt-fade-out-animation");

    this.$button.on("click", () => {
      this.$body.toggleClass(this.openMenuClass);

      var count = 0;

      if (this.$body.hasClass(this.openMenuClass)) {
        this.$animMenus.each(function(i, elem) {
          var $elem = $(elem);
          if ($elem.is(":visible")) {
            setTimeout(() => {
              $elem
                .removeClass("msnt-fade-out-animation")
                .addClass("msnt-fade-in-animation");
            }, 75 * count + 75);
            count++;
          }
        });
      } else {
        this.$animMenus.each(function(i, elem) {
          var $elem = $(elem);
          if ($elem.is(":visible")) {
            setTimeout(() => {
              $elem
                .removeClass("msnt-fade-in-animation")
                .addClass("msnt-fade-out-animation");
            }, 20 * count);
            count++;
          }
        });
      }
    });

    this.$container
      .find(".msnt-about_dropdown-arrow")
      .parent()
      .on("click", (e) => {
        this._toggleMenu(e);
      });

    if (this.menuOpened) {
      this._toggleMenu();
    }

    this.$overlay.on("click", () => {
      if (this.$body.hasClass(this.openMenuClass)) {
        this.$button.click();
      }
    });
  }

  _toggleMenu(e) {
    if (e) e.preventDefault();

    var $arrow = this.$container.find(".msnt-about_dropdown-arrow"),
      $target = $(".msnt-about_dropdown-menu");

    $target.slideToggle(300);
    $arrow.toggleClass("arrow-up");
    $target
      .children()
      .removeClass("msnt-fade-out-animation msnt-fade-in-animation");
  }
}
