export class Color {
    constructor(hex, transparent) {
      this.hex = hex || (transparent ? '#00000000' : '#ffffff');

      this.rgb = this.toRGBa(this.hex);
    }


    mix(color, percent) {

      if (percent < 0) { percent = 0; }
      if (percent > 1) { percent = 1; }

      let r = Math.round((color.rgb.r - this.rgb.r) * percent);
      let g = Math.round((color.rgb.g - this.rgb.g) * percent);
      let b = Math.round((color.rgb.b - this.rgb.b) * percent);
      let a = Math.round((color.rgb.a - this.rgb.a) * percent);
      
      return new Color(this.toHex(Math.abs(this.rgb.r + r), Math.abs(this.rgb.g + g), Math.abs(this.rgb.b + b), Math.abs(this.rgb.a + a)));
    }

    toRGBa(h) {
      if (!h) {
        return {r: 255, g: 255, b: 255};
      }
  
      let r = '', g = '', b = '';
    
      // 3 digits
      if (h.length == 4) {
        r = "0x" + h[1] + h[1];
        g = "0x" + h[2] + h[2];
        b = "0x" + h[3] + h[3];
    
      // 6 digits
      } else if (h.length == 7) {
        r = "0x" + h[1] + h[2];
        g = "0x" + h[3] + h[4];
        b = "0x" + h[5] + h[6];
      }
      
      return {r: +r, g: +g, b: +b};
    }

    toHex(r,g,b,a) {
      r = r.toString(16);
      g = g.toString(16);
      b = b.toString(16);
      a = b.toString(16);
    
      if (r.length == 1)
        r = "0" + r;
      if (g.length == 1)
        g = "0" + g;
      if (b.length == 1)
        b = "0" + b;
      if (a.length == 1)
        a = "0" + a;
    
      return "#" + r + g + b + a;
    }
}