export class PageHeader {
  constructor() {
    this.$container = $(".msnt-header");
    this._initEvents();
  }

  _initEvents() {
    $("#msnt-header_menu-open").on("click", () => {
      if (this.$container.data("toggle")) {
        this.$container.trigger("msnt-menu-close");
      } else {
        this.$container.trigger("msnt-menu-open");
      }
    });

    $("body").on("msnt-menu-open", () => {
      this.$container.addClass("msnt-header__menu-open");
      this.$container.css("z-index", 5);
      this.$container.data("toggle", 1);
    });

    $("body").on("msnt-menu-close", () => {
      this.$container.removeClass("msnt-header__menu-open");
      this.$container.data("toggle", 0);
      setTimeout(() => {
        this.$container.css("z-index", 1);
      }, 250);
    });

    var $bannerBlock = $(".changeHeader");

    if ($bannerBlock.length) {
      const headerOffsetTop = parseInt($("#header").css("top"));
      $(window).on("scroll", () => {
        if (
          $(window).scrollTop() -
            $bannerBlock.offset().top -
            $bannerBlock.height() * 0.4 +
            $("#header").outerHeight() +
            headerOffsetTop >=
          0
        ) {
          $("#header").addClass("invert");
        } else {
          $("#header").removeClass("invert");
        }
      });
    }
  }
}
